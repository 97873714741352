import React, { useContext, useEffect, useState } from "react";
import { env } from "../../env";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import axiosAdapter from "../../utils";
import DevContainerLifecycle from "./components/DevContainerLifecycle";

function NewApplicationPage() {
  const { showMessage } = useContext(SnackbarContext);
  const history = useHistory();
  const [createAppResponse, setCreateAppResponse] = useState(null);

  useEffect(() => {
    let workspaceId = localStorage.getItem("selectedWorkspaceId");
    if (!workspaceId) {
      history.push("/");
    }
    workspaceId = parseInt(workspaceId);
    (async () => {
      try {
        if (!workspaceId) {
          return;
        }
        const createAppResponse = await axiosAdapter(
          "POST",
          `${env.REACT_APP_URL}/pipeline/createApplication`,
          { workspaceId: workspaceId },
        );
        setCreateAppResponse(createAppResponse.data);
      } catch (err) {
        console.log("Error: createApplication", err);
        setCreateAppResponse({ code: 500 });
        if (err.response?.data?.remarks) {
          showMessage(err.response.data.remarks);
        } else {
          showMessage(
            "Failed to authenticate, please go back and try again later !!!",
          );
        }
      }
    })();
  }, [history, showMessage]);

  return <DevContainerLifecycle createAppResponse={createAppResponse} />;
}

export default NewApplicationPage;
