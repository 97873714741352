import React, { useCallback, useContext, useState } from "react";
import styled from "styled-components";
import { ReactComponent as DevContainerLifecycleSVG } from "../../../assets/images/dev_container_lifecycle.svg";
import FlexContainer from "../../../components/common/_flexContainer";
import Text from "../../../components/common/_text";
import { ReactComponent as DeployInitIcon } from "../../../assets/images/deploy_init.svg";
import { ReactComponent as DeploySuccessIcon } from "../../../assets/images/deploy_success.svg";
import { ReactComponent as DeployFailIcon } from "../../../assets/images/deploy_fail.svg";
import Slider from "../../../components/common/_slider";
import { StyledButtonPrimary } from "../../../components/common/_buttonNewOne";
import { useHistory } from "react-router-dom";
import axiosAdapter from "../../../utils";
import { env } from "../../../env";
import { SnackbarContext } from "../../../layouts/Context/snackBarContext";

const Main = styled.main`
  background: #0d0d0d;
  height: calc(100vh - 3.95rem);
  overflow: auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  align-content: flex-start;
  font-family: "Articulat CF Medium";
  font-weight: 500;
  font-size: 1.111rem;
  line-height: 1.3;
  color: #f6f6f6;
  text-align: start;
`;

const DevContainerLifecycleSVGStyled = styled(DevContainerLifecycleSVG)`
  height: 100%;
`;

const StatusIconContainer = styled(FlexContainer)`
  place-content: center;
  width: 2.222rem;
  height: 2.222rem;
  border: 0.0694rem solid
    ${({ $status }) =>
      $status === "SUCCESS"
        ? "#C6EFF2"
        : $status === "FAILURE"
        ? "#F6F6F6"
        : "#777777"};
  border-radius: 0.555rem;
  background-color: ${({ $status }) =>
    $status === "SUCCESS"
      ? "#47CCD6"
      : $status === "FAILURE"
      ? "#EA3A3A"
      : "#2F2F2F"};
`;

const DeployIconStyled = styled(DeployInitIcon)`
  width: 1.388rem;
  height: 1.388rem;
  & path {
    fill: ${({ $status }) =>
      $status === "SUCCESS" || $status === "FAILURE" ? "#F6F6F6" : "#777777"};
  }
`;

const SliderContainer = styled(FlexContainer)`
  width: 100%;
  justify-content: flex-start;
  margin: 0rem 0rem 5.555rem 0;
`;

const PrimaryButtonStyled = styled(StyledButtonPrimary)`
  width: 22.5rem;
`;

const DevContainerLifecycle = ({ createAppResponse }) => {
  const history = useHistory();
  const { showMessage } = useContext(SnackbarContext);

  const [expirationOffset, setExpirationOffset] = useState(3);

  const handleNextButtonClick = useCallback(async () => {
    if (!createAppResponse || !createAppResponse.code === 200) {
      showMessage("Failed to create application !!!");
      return;
    }

    try {
      await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/updateApplicationExpiry",
        {
          applicationId: createAppResponse.data.id,
          expiryOffset: expirationOffset,
        },
      );

      history.push("/repoInformation", {
        isRedirected: true,
        applicationId: createAppResponse.data.id,
      });
    } catch (err) {
      console.log("Error: updateApplicationExpiry", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Failed to update the application expiry !!!");
      }
    }
  }, [createAppResponse, expirationOffset, history, showMessage]);

  return (
    <Main>
      <DevContainerLifecycleSVGStyled />
      <FlexContainer
        $height="100%"
        $flex="1 0"
        $padding="0rem 2.777rem 0rem 5.555rem"
        $justifyContent="flex-start"
        $alignContent="center"
      >
        <Text $fontWeight="bold" $fontSize="1.944rem" $margin="0 0 1.111rem 0">
          Deploying Your Application
        </Text>
        <Text $margin="0 0 1.666rem 0" style={{ opacity: 0.7 }}>
          This will take a little while – we’re setting up everything for you.
          You'll see your connector live on the pipeline soon, ready to work
        </Text>
        <FlexContainer
          $width="100%"
          $margin="0 0 5.555rem 0"
          $justifyContent="flex-start"
          $gap="0rem 0.833rem"
          $textAlign="justify"
        >
          <StatusIconContainer $status="SUCCESS">
            <DeployIconStyled $status="SUCCESS" as={DeployInitIcon} />
          </StatusIconContainer>
          <Text
            $width="calc(100% - 2.222rem - 0.834rem)"
            $fontWeight="bold"
            $fontSize="1.25rem"
          >
            Deployment Initialised
          </Text>
          <FlexContainer
            $width="2.222rem"
            $height="1.111rem"
            $justifyContent="center"
          >
            <div
              style={{
                width: "0.0694rem",
                height: "100%",
                background:
                  "linear-gradient(180deg, rgba(71, 204, 214, 0) 7%, #47CCD6 100%)",
              }}
            />
          </FlexContainer>
          <FlexContainer $width="calc(100% - 2.222rem - 0.834rem)"></FlexContainer>
          <StatusIconContainer
            $status={
              createAppResponse?.code === 200
                ? "SUCCESS"
                : createAppResponse?.code === 500
                ? "FAILURE"
                : "IN_PROGRESS"
            }
          >
            <DeployIconStyled
              $status={
                createAppResponse?.code === 200
                  ? "SUCCESS"
                  : createAppResponse?.code === 500
                  ? "FAILURE"
                  : "IN_PROGRESS"
              }
              as={
                createAppResponse?.code === 500
                  ? DeployFailIcon
                  : DeploySuccessIcon
              }
            />
          </StatusIconContainer>
          <Text
            $width="calc(100% - 2.222rem - 0.834rem)"
            $fontWeight="bold"
            $fontSize="1.25rem"
          >
            {createAppResponse?.code === 500
              ? "Deployment Failed"
              : "Deployment Successful"}
          </Text>
        </FlexContainer>
        {(createAppResponse?.code === 200 || !createAppResponse) && (
          <>
            <Text
              $fontWeight="bold"
              $fontSize="1.944rem"
              $margin="0 0 1.111rem 0"
            >
              Development Environment Expiry
            </Text>
            <Text $margin="0 0 1.666rem 0" style={{ opacity: 0.7 }}>
              Please choose how long the container stays active. You can revisit
              this page to reset the expiration before it ends
            </Text>
            <SliderContainer>
              <Slider
                minValue={1}
                maxValue={7}
                markInterval={1}
                sliderValue={expirationOffset}
                setSliderValue={setExpirationOffset}
                markLabelFormatterFunction={(value) =>
                  `${value} ${value === 1 ? "Day" : "Days"}`
                }
              />
            </SliderContainer>
            <FlexContainer $width="100%" $justifyContent="flex-end">
              <PrimaryButtonStyled
                disabled={createAppResponse?.code !== 200}
                onClick={() => handleNextButtonClick()}
              >
                Next
              </PrimaryButtonStyled>
            </FlexContainer>
          </>
        )}
      </FlexContainer>
    </Main>
  );
};

export default DevContainerLifecycle;
