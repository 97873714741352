import {
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import Column from "../../components/common/_customColumn";
import CustomRow from "../../components/common/_customRow";
import styled from "styled-components";
import { useContext, useEffect, useRef, useState } from "react";
import loadingAnimation from "../../assets/images/Loading Animation.gif";
import { env } from "../../env";
import axiosAdapter from "../../utils";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import BackIcon from "../../assets/images/left_arrow_icon.svg";
import {
  StyledButtonPrimary,
  StyledButtonsecondary,
} from "../../components/common/_buttonNewOne";
import { getCurrentBranch, gitStatus } from "./utils";
import GenericModal from "../../components/common/_genericModal";
import useContainerDimensions from "../../hooks/useContainerDimentions";
import VSCodeUI from "./components/VSCodeUI";

// Constant values
const IMAGE_TAG_REGEX =
  /^(?:(?=[^:/]{1,253})(?!-)[a-zA-Z0-9-]{1,63}(?<!-)(?:\.(?!-)[a-zA-Z0-9-]{1,63}(?<!-))*(?::[0-9]{1,5})?\/)?((?![._-])(?:[a-z0-9._-]*)(?<![._-])(?:\/(?![._-])[a-z0-9._-]*(?<![._-]))*)(?::(?![.-])[a-zA-Z0-9_.-]{1,128})?$/;

const MainContainer = styled.div`
  background-color: #171717;
  height: 100%;
  width: 100%;
`;

const ParentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 95vh;
  background-color: #171717;
`;

const AnimationContainer = styled.div`
  position: relative;
  width: 240px;
`;

const ScrollAnimationBar = styled.div`
  width: 100%;
  height: 4px;
  background-color: #2f2f2f;
  border-radius: 2px;
  box-shadow: -8px 4px 9px 0px #2f2f2f;
  position: absolute;
  bottom: 0;
  left: 0;
`;

const LoadingAnimation = styled.img`
  width: 100%;
  bottom: 4px;
`;

const Text = styled.p`
  color: #f6f6f6;
  font-size: 1.67rem;
  margin: 0;
`;

const ModalSubtext = styled.p`
  font-family: "Articulat CF Medium";
  margin: 0;
  font-size: 1.25rem;
  color: #ececec;
  opacity: 0.7;
  letter-spacing: 0px;
`;

const ModalTextFieldOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (min-width: 992px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    gap: 1.112rem;
  }
`;
const ModalTextfieldContainer = styled.div`
  width: 100%;
  margin-top: 2.084rem;
`;

const ModalWarningfieldContainer = styled.div`
  width: 100%;
  height: 0.5rem;
`;

const ModalTextfield = styled.input`
  padding: 0.7rem;
  outline: none;
  background: inherit;
  width: 100%;
  border-radius: 0.55rem;
  border: 0.138rem solid #909090;
  color: #ececec;
  font-size: 1.112rem;
  font-family: Articulat CF Medium;
  &::placeholder {
    font-family: Articulat CF Medium;
    color: #b0b0b0;
    font-size: 0.972rem;
  }
`;

const ModalButtonContainer = styled.div`
  margin-top: 2.78rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.39rem;
`;

const ModalWarningText = styled.p`
  font-family: "Articulat CF Medium";
  margin: 0;
  font-size: 0.972rem;
  color: #ea3a3a;
  /* margin-top: 1rem; */
`;

const ModalContent = styled.div`
  min-height: 33vh;
`;

const VSCodeEditor = () => {
  const location = useLocation();
  const history = useHistory();
  const { showMessage } = useContext(SnackbarContext);

  // State variables
  const [isLoading, setLoading] = useState(true);
  const [podName, setPodName] = useState("");
  const [currentBranch, setCurrentBranch] = useState("");
  const [showBuildModal, setShowBuildModal] = useState(false);
  const [redirectToBuildPage, setRedirectToBuildPage] = useState(false);
  const [imageName, setImageName] = useState(location.state?.imageName || "");
  const [tagName, setTagName] = useState(location.state?.tag || "");
  const [isImageTagValid, setImageTagValidity] = useState(
    location.state?.imageName ? true : false,
  );

  const editorContainerRef = useRef(null);
  const editorContainerDimensions = useContainerDimensions(editorContainerRef);

  useEffect(() => {
    (async () => {
      try {
        let podDetailsResponse = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "pipeline/getApplicationContainer",
          {
            applicationId: location.state.applicationId,
          },
        );
        if (
          podDetailsResponse.data &&
          podDetailsResponse.data.status === "Success"
        ) {
          let match = podDetailsResponse.data.data.podName.match(
            /devel-container-deployment-([^-\s]+)/,
          );
          if (match) {
            setPodName(match[1]);
            setLoading(false);
          } else {
            throw new Error("Could not find the pod name");
          }
        } else {
          throw new Error("Unsuccess response for the pod details request");
        }
      } catch (err) {
        console.log("Error: getApplicationContainer", err);
        showMessage("Error fetching pod details");
        history.push("/applications");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!Boolean(location.state?.isRedirected))
    return <Redirect to={{ pathname: "/" }} />;

  // Redirect to Build page where all the require props are collected for build process and user clicks build in the Modal
  if (redirectToBuildPage) {
    return (
      <Redirect
        to={{
          pathname: "/buildLogs",
          state: {
            isRedirected: true,
            imageName: imageName,
            tagName: tagName,
            repoName: location.state?.repositoryName,
            branch: currentBranch,
            username: location.state?.gitUsername,
            applicationId: location.state?.applicationId,
            oldApplicationName: location.state?.applicationName,
            oldApplicationVersion: location.state?.applicationVersion,
            oldDescription: location.state?.description,
            oldIconFile: location.state?.icon_file,
            oldUserConfigs: location.state?.userConfigs,
          },
        }}
      />
    );
  }

  return (
    <>
      {isLoading && (
        <ParentContainer>
          <AnimationContainer>
            <ScrollAnimationBar />
            <LoadingAnimation src={loadingAnimation} alt="Loading Animation" />
          </AnimationContainer>
        </ParentContainer>
      )}
      <MainContainer
        style={isLoading ? { visibility: "hidden" } : { visibility: "visible" }}
      >
        <CustomRow>
          <Column
            xs={12}
            style={{
              padding: "1.112rem 1.112rem",
            }}
          >
            <CustomRow>
              <Column
                xl={6}
                lg={6}
                xs={12}
                md={6}
                xxl={6}
                xxxl={6}
                style={{ display: "flex", gap: "1.112rem" }}
              >
                <img
                  src={BackIcon}
                  style={{
                    height: "2.225rem",
                    width: "2.225rem",
                    cursor: "pointer",
                    marginTop: "0.138rem",
                  }}
                  alt="Go Back"
                  onClick={() => history.push("/applications")}
                />
                <Text
                  style={{
                    fontFamily: "Articulat CF Medium",
                    fontWeight: "bold",
                  }}
                >
                  Configure your{" "}
                  {location.state?.applicationName ?? "New Application"}
                </Text>
              </Column>
              <Column
                xs={6}
                md={1.74}
                sm={1.74}
                lg={1.74}
                xl={1.74}
                xxl={1.74}
                xxxl={1.74}
              >
                <StyledButtonPrimary
                  style={{ width: "14.167rem" }}
                  onClick={async () => {
                    try {
                      setLoading(true);
                      let status = await gitStatus({
                        applicationId: location.state?.applicationId,
                      });
                      if (status) {
                        let currentBranch = await getCurrentBranch({
                          applicationId: location.state?.applicationId,
                          repoName: location.state?.repositoryName,
                        });
                        if (
                          currentBranch &&
                          currentBranch.status === "Success"
                        ) {
                          setCurrentBranch(currentBranch.branchName);
                          setLoading(false);
                          setShowBuildModal(true);
                        } else {
                          showMessage(
                            "Failed to obtain the current branch",
                            "error",
                          );
                          setLoading(false);
                        }
                      } else {
                        setLoading(false);
                        showMessage(
                          "Seems like your codebase has uncommitted code or isn't in sync with the remote repository",
                        );
                      }
                    } catch (err) {
                      setLoading(false);
                      console.log("Error: gitStatus", err);
                      showMessage(
                        "Failed to obtain the current status of the codebase",
                        "error",
                      );
                    }
                  }}
                >
                  Publish Application
                </StyledButtonPrimary>
              </Column>
            </CustomRow>
          </Column>
          <Column
            style={{
              height: "calc(100vh - 8.99rem)",
            }}
            ref={editorContainerRef}
          >
            {!isLoading && (
              <VSCodeUI
                height={editorContainerDimensions.height}
                width={editorContainerDimensions.width}
                podName={podName}
                repoName={location.state?.repositoryName || ""}
              />
            )}
          </Column>
        </CustomRow>
      </MainContainer>
      <GenericModal
        show={showBuildModal}
        title={"Add Details to Build your Application"}
        onClose={() => setShowBuildModal(false)}
        // maxHeight="60vh"
      >
        {/* <ModalContent> */}
        <ModalSubtext>
          One last step before we can build your application. We need a few
          details before we can build your application to start using this.
        </ModalSubtext>
        <ModalTextFieldOuterContainer>
          <ModalTextfieldContainer>
            <ModalTextfield
              placeholder="Enter the Name of Image *"
              value={imageName}
              onChange={(e) => {
                setImageName(e.target.value);
                setImageTagValidity(
                  IMAGE_TAG_REGEX.test(e.target.value + ":" + tagName),
                );
              }}
            />
          </ModalTextfieldContainer>
          <ModalTextfieldContainer>
            <ModalTextfield
              placeholder="Enter the Value of Tag *"
              value={tagName}
              onChange={(e) => {
                setTagName(e.target.value);
                setImageTagValidity(
                  IMAGE_TAG_REGEX.test(imageName + ":" + e.target.value),
                );
              }}
            />
          </ModalTextfieldContainer>
        </ModalTextFieldOuterContainer>
        <ModalWarningfieldContainer>
          {!isImageTagValid && (imageName.length || tagName.length) ? (
            <ModalTextfieldContainer
              style={{ marginTop: "0rem", padding: "0px 0.7rem" }}
            >
              <ModalWarningText>
                Combination of Image and Tag is not in accordance with standard
                docker image:tag format
              </ModalWarningText>
            </ModalTextfieldContainer>
          ) : null}
        </ModalWarningfieldContainer>
        <ModalButtonContainer>
          <StyledButtonsecondary
            onClick={() => setShowBuildModal(false)}
            style={{ width: "14.167rem" }}
          >
            Cancel
          </StyledButtonsecondary>
          <StyledButtonPrimary
            style={{ width: "14.167rem" }}
            disabled={
              !isImageTagValid || imageName.length === 0 || tagName.length === 0
            }
            onClick={() => setRedirectToBuildPage(true)}
          >
            Build Application
          </StyledButtonPrimary>
        </ModalButtonContainer>
        {/* </ModalContent> */}
      </GenericModal>
    </>
  );
};

export default VSCodeEditor;
