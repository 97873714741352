import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import NavBar from "../components/NavBar/NavBar";
import { SnackbarProvider } from "../layouts/Context/snackBarContext";
import TopSnackBar from "../components/common/_topSnackBar";
import { AuthContext } from "../context/AuthContext";
import SideBar from "../components/SideBar/SideBar";
import { Col, Row } from "reactstrap";
import CreateEditWorkspaceModalContextProvider from "../context/CreateEditWorkspaceModalContext";

/**
 *
 * @description (AppRoute - Defines Page Routes)
 * @param {*} { component: Component, isAuthProtected, ...rest }
 */
const /**
   *
   *
   * @param {*} props
   * @return {*}
   */
  AppRoute = ({ component: Component, isAuthProtected, ...rest }) => {
    const routes = {
      HOME: "/",
      CONNECTORS_PLAYGROUND: "/connectorsPlayground",
      ON_BOARDING: "/onBoarding",
      CONNECTORS: "/connectors",
      APPLICATIONS: "/applications",
      TERMINAL: "/buildLogs",
      TERMINAL_IFRAME: "/terminal-iframe",
      CONNECTOR_INFORMATION: "/connectorInformation",
      CONNECTOR_CONFIGURATION: "/connectorConfiguration",
      CODE_EDITOR: "/codeEditor",
      OAUTH_AUTHORIZE: "/oauth/authorize",
      CREATE_APPLICATION: "/createApplication",
      ACTIVITY_AUDITOR: "/activityAuditor",
      // Add more routes here
    };

    const { logout, isTokenPresent, expiryTime } = useContext(AuthContext);
    let _currentTime = Math.floor(Date.now() / 1000);

    // If the token is not present and the route is protected, it means its not logged in
    if (!isTokenPresent && isAuthProtected) {
      return <Redirect to={{ pathname: "/" }}></Redirect>;
    }

    if (isAuthProtected && expiryTime && _currentTime > expiryTime) {
      logout();
      return <Redirect to={{ pathname: "/" }} />;
    } else if (!isAuthProtected && expiryTime && _currentTime < expiryTime) {
      return <Redirect to={{ pathname: "/onBoarding" }} />;
    }
    // Add the routes where you don't want to render the sidebar
    // Add more routes if needed
    const routeWithoutSkeleton = [routes.TERMINAL_IFRAME];
    const routesWithoutSidebar = [
      routes.TERMINAL,
      routes.ON_BOARDING,
      routes.CONNECTOR_INFORMATION,
      routes.CODE_EDITOR,
      routes.OAUTH_AUTHORIZE,
      routes.CONNECTOR_CONFIGURATION,
      routes.CREATE_APPLICATION,
      routes.ACTIVITY_AUDITOR,
    ]; // Add more routes if needed

    // Check if the current route path is in the routesWithoutSidebar array
    const isSideBar = !routesWithoutSidebar.includes(rest.path);
    return (
      <Route
        {...rest}
        render={(props) => {
          if (isAuthProtected) {
            if (routeWithoutSkeleton.includes(rest.path)) {
              return <Component {...props} />;
            }

            return (
              <SnackbarProvider>
                <CreateEditWorkspaceModalContextProvider>
                  <TopSnackBar />
                  {isAuthProtected && <NavBar />}
                  <Row noGutters>
                    {isSideBar && (
                      <Col xs={2}>
                        <SideBar />
                      </Col>
                    )}
                    <Col xs={isSideBar ? 10 : 12}>
                      <Component {...props} />
                    </Col>
                  </Row>
                </CreateEditWorkspaceModalContextProvider>
              </SnackbarProvider>
            );
          } else {
            return (
              <SnackbarProvider>
                <TopSnackBar />
                <Component {...props} />
              </SnackbarProvider>
            );
          }
        }}
      />
    );
  };

export default AppRoute;
