import React, { useEffect, useState, useContext } from "react";
import GenericModal from "../common/_genericModal";
import styled from "styled-components";
import Column from "../../components/common/_customRow";
import CustomRow from "../../components/common/_customRow";
import CalendarIcon from "../../assets/images/CalendarIcon.svg";
import {
  StyledButtonPrimary,
  StyledButtonsecondary,
} from "../common/_buttonNewOne";
import DropUp from "../../assets/images/dropupfinal.svg";
import DropDown from "../../assets/images/dropdownfinal.svg";
import CloseIcon from "../../assets/images/closeC2D.svg";
import Calendar from "../common/Calendar";
import AdditoinIcon from "../../assets/images/AdditionIcon.svg";
import DashBorder from "../../assets/images/DashBorder.svg";
import UploadIcon from "../../assets/images/UploadIcon.svg";
import axios from "axios";
import { env } from "../../env";
import jwtDecode from "jwt-decode";
import { AuthContext } from "../../context/AuthContext";
import ClearAllIcon from "../../assets/images/ClearAllIcon.svg";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";

const InputFieldArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const InputField = styled.div`
  border: 0.1rem solid var(--grey-1, #777777);
  border-radius: 0.41rem;
  width: 24.8rem;
  position: relative;
  margin-right: 1.112rem;
`;

const StyledRow = styled.div`
  display: flex;
  margin: 0;
  padding: 0;
`;

const InputText = styled.input`
  font-family: "Articulat CF Medium";
  font-size: 1rem;
  font-weight: 500;
  color: #fafafa;
  outline: none;
  border: none;
  background-color: transparent;
  width: 100%;
  padding-right: 2.5rem;
  &::placeholder {
    color: #b0b0b0;
    opacity: 0.7;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

const DropdownContainer = styled.div`
  position: absolute;
  background-color: #333;
  border: 0.1rem solid #777777;
  border-radius: 0.41rem;
  margin-top: 0.5rem;
  width: 95.5%;
  z-index: 10;
  overflow-y: auto;
  max-height: 12rem;
`;

const DropdownItem = styled.div`
  padding: 0.5rem 1rem;
  color: white;
  cursor: pointer;
  &:hover {
    background-color: #555;
  }
`;

const CustomCommandComponent = styled.div`
  width: 50.7rem;
  height: 2.77rem;
  background-color: #47ccd629;
  border: 1px solid #47ccd6;
  border-radius: 1.67rem;
  margin-top: 0.55rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
`;

const Command = styled.div`
  color: #f6f6f6;
  flex-grow: 1;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ModalContent = styled.div`
  height: 28rem;
  display: flex;
  flex-direction: column;
  overflow: visible;
`;

const ImeiList = styled.div`
  margin-top: 0.5rem;
  max-height: 17rem;
  width: 52rem;
  overflow-x: auto;
  display: flex;
  flex-wrap: wrap;
  column-gap: 1.112rem;
`;

const BulkUpload = styled.div`
  width: 50.6rem;
  height: 16.67rem;
  position: relative;
`;

const BulkEmptystate = styled.div`
  width: 23.472rem;
  height: 7.5rem;
  z-index: 1;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const UploadLinkText = styled.div`
  color: #47ccd6;
  border-bottom: 1px solid #47ccd6;

  font-family: "Articulat CF Medium";
  font-size: 1.112rem;
`;

const Uploadtext = styled.div`
  width: 23.472rem;
  height: 3.055rem;
  font-family: "Articulat CF Medium";
  font-size: 1.112rem;
  color: #b0b0b0;
`;

const ImeiItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1e565a;
  padding: 0.84rem 0.84rem 0.84rem 1.112rem;
  margin-bottom: 0.5rem;
  border-radius: 1.67rem;
  width: 11.82rem;
  height: 2.77rem;
  border: 1px solid #47ccd6;
  color: #f6f6f6;
`;

const ImeiInvalid = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #4d3131;
  padding: 0.84rem 0.84rem 0.84rem 1.112rem;
  margin-bottom: 0.5rem;
  border-radius: 1.67rem;
  width: 11.82rem;
  height: 2.77rem;
  border: 1px solid #ea3a3a;
  color: #f6f6f6;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
`;

const CloseButtonCmd = styled.button`
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
`;

const CalendarWrapper = styled.div`
  position: absolute;
  top: 3.5rem;
  left: 0;
  z-index: 10;
  background-color: #333;
  border: 0.1rem solid #777;
  border-radius: 0.41rem;
  padding: 1rem;
`;

const CustomCommand = styled.div`
  padding: 0.5rem 1rem;
  color: #47ccd6;
  cursor: pointer;
  &:hover {
    background-color: #555;
  }
`;

const ScrollableContainer = styled.div`
  /* width: 100%; */
  /* width: 52rem; */
  height: 17rem;
  overflow-y: scroll;
`;

const ClearAll = styled.div`
  color: #47ccd6;
  width: 6.55rem;
  height: 1.112rem;
  padding-top: 4.3rem;
  cursor: pointer;
`;

function CampaignModal({
  onClose,
  onAddCampaign,
  isModalOpen,
  fetchCampaigns,
  resetpage,
}) {
  const [campaignName, setCampaignName] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [isCommandDropdownOpen, setIsCommandDropdownOpen] = useState(false);
  const [isImeiDropdownOpen, setIsImeiDropdownOpen] = useState(false);
  const [selectedCommand, setSelectedCommand] = useState("");
  const [imeiInput, setImeiInput] = useState("");
  const [imeiList, setImeiList] = useState([]);
  const [isInvalid, setIsInvalid] = useState(false);
  const [imeiInputMode, setImeiInputMode] = useState("");
  const [bulkUploadMode, setbulkUpload] = useState("");
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [newDate, setnewDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [customCommand, setCustomCommand] = useState("");
  const [arrCustomCommand, setarrCustomCommand] = useState([]);
  const [isCustomCommandInputVisible, setIsCustomCommandInputVisible] =
    useState(false);
  const { showMessage } = useContext(SnackbarContext);

  useEffect(() => {
    const userName = localStorage.getItem("userName");

    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
  }, []);
  const [commands, setCommands] = useState([
    "Command 1",
    "Command 2",
    "Command 3",
    "Command 4",
    "Command 5",
    "Command 6",
    "Command 7",
    "Command 8",
  ]);

  const { userFullName } = useContext(AuthContext);

  const handleCloseModal = () => {
    onClose();
  };

  const handleCommandSelect = (command) => {
    setSelectedCommand(command);
    setIsCommandDropdownOpen(false);
    setIsCustomCommandInputVisible(false);
  };

  const handleCustomCommand = () => {
    setSelectedCommand("Write Custom Command");
    setIsCustomCommandInputVisible(true);
    setIsCommandDropdownOpen(false);
  };

  const handleAddCustomCommand = () => {
    if (customCommand.trim() != "") {
      if (arrCustomCommand.length > 49) {
        showMessage("Limit exceeded !!!");
      } else {
        setarrCustomCommand([...arrCustomCommand, customCommand]);
        setCustomCommand("");
      }
    }
  };

  const handleCustomCommandChange = (index, newValue) => {
    const updatedCommands = [...arrCustomCommand];
    updatedCommands[index] = newValue;
    setarrCustomCommand(updatedCommands);
  };
  const handleClearAll = () => {
    setCustomCommand("");
    setarrCustomCommand("");
    setImeiList([]);
    setImeiInput("");
    setIsCustomCommandInputVisible(false);
    setSelectedCommand("");
  };

  const toggleCalendar = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  const handleDateSelect = (date) => {
    const formattedDate = date.toISOString().split("T")[0];
    setExpiryDate(formattedDate);
    setIsCalendarOpen(false);
  };

  const handleCreateCampaign = async () => {
    if (campaignName && expiryDate) {
      const newCampaign = {
        campaignName: campaignName,
        createdBy: userFullName,
        expiryDate: expiryDate,
        customCommand: isCustomCommandInputVisible,
        command: isCustomCommandInputVisible
          ? arrCustomCommand
          : selectedCommand,
      };

      if (!isCustomCommandInputVisible) {
        newCampaign.imei = imeiList;
      }

      try {
        const response = await axios.post(
          env.REACT_APP_URL + "/command/createCampaign",
          newCampaign,
        );

        if (response.status === 200) {
          onAddCampaign(response.data);
          fetchCampaigns();
          resetpage();
          onClose();
        } else {
          throw new Error("Failed to create campaign");
        }
      } catch (error) {
        const errorMessage =
          error.response?.data?.remarks ||
          "An error occurred while creating the campaign";
        showMessage(errorMessage, "error");
      }
    }
  };

  const toggleCommandDropdown = () => {
    setIsCommandDropdownOpen(!isCommandDropdownOpen);
  };

  const toggleImeiDropdown = () => {
    setIsImeiDropdownOpen(
      isCustomCommandInputVisible ? isImeiDropdownOpen : !isImeiDropdownOpen,
    );
  };

  const handleImeiInputModeSelect = (mode) => {
    setImeiInputMode(mode);
    setIsImeiDropdownOpen(false);
  };

  const handleAddImei = () => {
    if (imeiInput && !imeiList.includes(imeiInput)) {
      setImeiList([...imeiList, imeiInput]);
      setImeiInput("");
    }
  };

  useEffect(() => {
    let res = imeiList.filter((ele) => ele.length != 15);
    if (res.length == 0) {
      setIsInvalid(false);
    } else {
      setIsInvalid(true);
    }
  }, [imeiList]);

  const handleRemoveImei = (imei) => {
    setImeiList(imeiList.filter((item) => item !== imei));
  };

  const handleRemoveCommand = (indexToRemove) => {
    setarrCustomCommand(
      arrCustomCommand.filter((_, index) => index !== indexToRemove),
    );
  };

  // const customCommands = ["Command 1"];

  return (
    <GenericModal
      show={isModalOpen}
      onClose={onClose}
      title="Create a New Campaign"
      maxHeight="85vh"
    >
      <ModalContent>
        <div className="userInput">
          <CustomRow>
            <InputFieldArea>
              <StyledRow style={{ display: "flex" }}>
                <Column xs={5.88}>
                  <InputField style={{ height: "3.34rem" }}>
                    <StyledRow
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingTop: "0.77rem",
                        paddingLeft: "1.13rem",
                      }}
                    >
                      <Column>
                        <InputText
                          placeholder="Campaign Name"
                          maxLength={23}
                          style={{ width: "100%" }}
                          value={campaignName}
                          onChange={(e) => setCampaignName(e.target.value)}
                        />
                      </Column>
                    </StyledRow>
                  </InputField>
                </Column>

                <Column xs={5.88}>
                  <div style={{ position: "relative" }}>
                    <InputField
                      style={{
                        height: "3.34rem",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "1.13rem",
                      }}
                    >
                      <InputText
                        placeholder="Enter Campaign Expiry Date"
                        value={expiryDate}
                        readOnly
                      />
                      <IconWrapper onClick={toggleCalendar}>
                        <img
                          style={{
                            paddingRight: "1rem",
                            height: "2.112rem",
                            width: "2.112rem",
                          }}
                          src={CalendarIcon}
                          alt="Calendar Icon"
                        />
                      </IconWrapper>
                    </InputField>
                    {isCalendarOpen && (
                      <CalendarWrapper>
                        <Calendar
                          onDateSelect={handleDateSelect}
                          setSelectedDate={setSelectedDate}
                          selectedDate={selectedDate}
                        />
                      </CalendarWrapper>
                    )}
                  </div>
                </Column>
              </StyledRow>

              <StyledRow style={{ display: "flex" }}>
                <Column xs={5.88}>
                  <div style={{ position: "relative" }}>
                    <InputField
                      style={{
                        height: "3.34rem",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "1.13rem",
                        // disabled: { isCustomCommandInputVisible },
                        cursor: isCustomCommandInputVisible
                          ? "not-allowed"
                          : "pointer",

                        opacity: isCustomCommandInputVisible ? 0.4 : 1,
                        pointerEvents: isCustomCommandInputVisible
                          ? "none"
                          : null,
                      }}
                    >
                      <InputText
                        placeholder="Choose your Command here"
                        value={selectedCommand}
                        readOnly
                        style={{ flex: 1 }}
                        disabled={true}
                      />
                      <IconWrapper
                        onClick={toggleCommandDropdown}
                        disabled={true}
                        style={{
                          cursor: isCustomCommandInputVisible
                            ? "not-allowed"
                            : "pointer",
                        }}
                      >
                        <img
                          src={isCommandDropdownOpen ? DropUp : DropDown}
                          alt="Toggle Dropdown"
                          style={{
                            paddingRight: "1rem",
                            height: "2.112rem",
                            width: "2.112rem",
                          }}
                          disabled={true}
                        />
                      </IconWrapper>
                    </InputField>
                    {isCommandDropdownOpen && (
                      <DropdownContainer>
                        <DropdownItem
                          style={{
                            color: "#47ccd6",
                            background: "#4B4B4B",
                          }}
                          onClick={() => {
                            handleCustomCommand();
                          }}
                        >
                          Write Custom Command
                        </DropdownItem>
                        {commands.map((command, index) => (
                          <DropdownItem
                            key={index}
                            onClick={() => handleCommandSelect(command)}
                            disabled={true}
                          >
                            {command}
                          </DropdownItem>
                        ))}
                      </DropdownContainer>
                    )}
                  </div>
                </Column>

                <Column xs={5.88}>
                  <div style={{ position: "relative" }}>
                    <InputField
                      style={{
                        height: "3.34rem",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "1.13rem",
                        opacity: isCustomCommandInputVisible ? 0.4 : 1,
                      }}
                    >
                      <InputText
                        placeholder="IMEI Input Method"
                        value={
                          imeiInputMode === "single"
                            ? "Add single IMEI"
                            : imeiInputMode === "bulk"
                            ? "Bulk upload"
                            : ""
                        }
                        readOnly
                        style={{ flex: 1 }}
                        disabled={true}
                      />
                      <IconWrapper onClick={toggleImeiDropdown}>
                        <img
                          src={isImeiDropdownOpen ? DropUp : DropDown}
                          alt="Toggle Dropdown"
                          style={{
                            paddingRight: "1rem",
                            height: "2.112rem",
                            width: "2.112rem",
                          }}
                        />
                      </IconWrapper>
                    </InputField>
                    {isImeiDropdownOpen && (
                      <DropdownContainer>
                        <DropdownItem
                          onClick={() => handleImeiInputModeSelect("single")}
                        >
                          Add single IMEI
                        </DropdownItem>
                        <DropdownItem
                          style={{
                            opacity: 0.4,
                            pointerEvents: "none",
                          }}
                          onClick={() => handleImeiInputModeSelect("bulk")}
                        >
                          Bulk upload
                        </DropdownItem>
                      </DropdownContainer>
                    )}
                  </div>
                </Column>
              </StyledRow>

              <div>
                {/* Custom Command Section */}
                {isCustomCommandInputVisible ? (
                  <div>
                    <ScrollableContainer>
                      {arrCustomCommand.length > 0 && (
                        <div>
                          {arrCustomCommand.map((ele, index) => (
                            <CustomCommandComponent key={index}>
                              {/* <Command>{ele}</Command> */}
                              <InputText
                                placeholder="Enter your custom command"
                                value={ele}
                                onChange={(e) =>
                                  handleCustomCommandChange(
                                    index,
                                    e.target.value,
                                  )
                                }
                              />
                              <CloseButtonCmd
                                onClick={() => handleRemoveCommand(index)}
                              >
                                <img src={CloseIcon} alt="Remove command" />
                              </CloseButtonCmd>
                            </CustomCommandComponent>
                          ))}
                        </div>
                      )}

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "1.112rem",
                        }}
                      >
                        <InputField
                          style={{
                            height: "2.77rem",
                            width: "50.6rem",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "1.13rem",
                            borderRadius: "1.67rem",
                          }}
                        >
                          <InputText
                            placeholder="Enter your custom command"
                            value={customCommand}
                            onChange={(e) => setCustomCommand(e.target.value)}
                          />
                        </InputField>
                      </div>

                      <StyledButtonPrimary
                        style={{
                          width: "2.777rem",
                          height: "2.777rem",
                          borderRadius: "1.67rem",
                          marginTop: "1rem",
                          background: "#4B4B4B",
                        }}
                        onClick={handleAddCustomCommand}
                      >
                        <img src={AdditoinIcon} alt="Add custom command" />
                      </StyledButtonPrimary>
                    </ScrollableContainer>
                    {arrCustomCommand.length > 0 && (
                      <ClearAll onClick={handleClearAll}>
                        <img
                          style={{
                            paddingBottom: "0.1rem",
                            paddingRight: "0.55rem",
                          }}
                          src={ClearAllIcon}
                        />
                        Clear All
                      </ClearAll>
                    )}
                  </div>
                ) : (
                  /* IMEI Input Section */

                  <div>
                    <ScrollableContainer>
                      <ImeiList>
                        {imeiList.length > 0 &&
                          imeiList.map((imei, index) => {
                            const imeiLength = imei.length;
                            return imeiLength === 15 ? (
                              <ImeiItem key={index}>
                                {imei}
                                <CloseButton
                                  onClick={() => handleRemoveImei(imei, index)}
                                >
                                  <img src={CloseIcon} alt="Remove IMEI" />
                                </CloseButton>
                              </ImeiItem>
                            ) : (
                              <ImeiInvalid key={index}>
                                {/* {setIsInvalid(true)} */}
                                {imei}
                                <CloseButton
                                  onClick={() => handleRemoveImei(imei)}
                                >
                                  <img src={CloseIcon} alt="Remove IMEI" />
                                </CloseButton>
                              </ImeiInvalid>
                            );
                          })}
                        {imeiInputMode === "single" && (
                          <StyledRow>
                            <InputField
                              style={{
                                width: "11.805rem",
                                height: "2.77rem",
                                paddingLeft: "1.13rem",
                                paddingTop: "0.55rem",
                                background: "#4B4B4B",
                                borderRadius: "1.67rem",
                              }}
                            >
                              <StyledRow
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <InputText
                                  placeholder="Enter IMEI"
                                  value={imeiInput}
                                  onChange={(e) => setImeiInput(e.target.value)}
                                />
                              </StyledRow>
                            </InputField>
                          </StyledRow>
                        )}
                        {imeiInputMode === "single" && (
                          <StyledButtonPrimary
                            style={{
                              width: "2.777rem",
                              height: "2.777rem",
                              borderRadius: "1.67rem",
                              background: "#4B4B4B",
                            }}
                            onClick={handleAddImei}
                          >
                            <img src={AdditoinIcon} alt="Add IMEI" />
                          </StyledButtonPrimary>
                        )}
                      </ImeiList>

                      <BulkUpload>
                        {imeiInputMode === "bulk" && (
                          <>
                            <img
                              style={{
                                width: "50.6rem",
                                height: "16.67rem",
                                zIndex: 0,
                              }}
                              src={DashBorder}
                            />
                            <BulkEmptystate>
                              <img
                                style={{
                                  height: "2.77rem",
                                  width: "2.77rem",
                                  display: "flex",
                                  marginLeft: "10.347rem",
                                }}
                                src={UploadIcon}
                              />

                              <Uploadtext>
                                <UploadLinkText>Click to upload</UploadLinkText>
                                or drag and drop your file .XLS (max 30MB)
                              </Uploadtext>
                            </BulkEmptystate>
                          </>
                        )}
                      </BulkUpload>
                    </ScrollableContainer>

                    {imeiList.length > 0 && (
                      <ClearAll onClick={handleClearAll}>
                        <img
                          style={{
                            paddingBottom: "0.1rem",
                            paddingRight: "0.55rem",
                          }}
                          src={ClearAllIcon}
                        />
                        Clear All
                      </ClearAll>
                    )}
                  </div>
                )}
              </div>
            </InputFieldArea>
          </CustomRow>
        </div>
      </ModalContent>

      <StyledRow
        style={{
          justifyContent: "flex-end",
          marginTop: "1rem",
        }}
      >
        <Column>
          <StyledButtonsecondary
            style={{ width: "14.167rem", marginRight: "1.112rem" }}
            onClick={handleCloseModal}
          >
            Cancel
          </StyledButtonsecondary>
        </Column>
        <Column>
          <StyledButtonPrimary
            style={{ width: "14.167rem" }}
            onClick={handleCreateCampaign}
            disabled={
              campaignName === "" ||
              expiryDate === "" ||
              (selectedCommand === "" && !isCustomCommandInputVisible) ||
              (isCustomCommandInputVisible && arrCustomCommand.length === 0) ||
              (!isCustomCommandInputVisible && imeiList.length == 0) ||
              isInvalid
            }
          >
            Create Campaign
          </StyledButtonPrimary>
        </Column>
      </StyledRow>
    </GenericModal>
  );
}

export default CampaignModal;
